import React, { useState } from "react";

const SchedulePage = () => {
  // Generate hours for the day
  const hours = Array.from({ length: 24 }, (_, i) => {
    const hour = i % 12 === 0 ? 12 : i % 12;
    const period = i < 12 ? "AM" : "PM";
    return `${hour}:00 ${period}`;
  });

  // State for tasks or notes
  const [tasks, setTasks] = useState({});

  // Handle input changes
  const handleInputChange = (hour, value) => {
    setTasks((prevTasks) => ({
      ...prevTasks,
      [hour]: value,
    }));
  };

  return (
    <div style={{ maxWidth: "600px", margin: "0 auto", padding: "20px" }}>
      <h1>Daily Planner</h1>
      <div style={{ border: "1px solid #ccc", borderRadius: "8px", overflow: "hidden" }}>
        {hours.map((hour) => (
          <div
            key={hour}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: "1px solid #eee",
              padding: "10px 15px",
            }}
          >
            <span>{hour}</span>
            <input
              type="text"
              placeholder="Add a task or note"
              value={tasks[hour] || ""}
              onChange={(e) => handleInputChange(hour, e.target.value)}
              style={{ flex: 1, marginLeft: "10px" }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SchedulePage;