import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {AssignedTask} from '../utils/TaskMaster'; // Assuming Task is imported from utils
import Timer from '../utils/timer';

const TaskView = () => {
  const location = useLocation();
  const [tasks, setTasks] = useState([]);
  const [currentTaskIndex, setCurrentTaskIndex] = useState(0);
  const [currentTask, setCurrentTask] = useState(null);
  const [timeLeft, setTimeLeft] = useState(currentTask ? currentTask.timer.getTimeLeft() : '00:00:00');
  const navigate = useNavigate();

  // Retrieve the taskId from the location state
  const { taskId } = location.state || {};

  useEffect(() => {
    if (taskId) {
      // Make the API request to the backend to get task details using fetch
      fetch(`/api/task/${taskId}/assigned_tasks`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include' // if needed
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          // Convert the JSON data into a Task object
          const tasks = data.map(item => new AssignedTask(item));
          setTasks(tasks);
          setCurrentTask(tasks[0]);
          setTimeLeft(currentTask.timer.getTimeLeft());
        })
        .catch(error => {
          // Handle errors
          console.error('Error fetching the task data:', error);
        });
    }
  }, [taskId]);
 
  useEffect(() => {
    if (currentTask){
      if (currentTask.start){
        currentTask.timer.start(currentTask.start);
      }
      setTimeLeft(currentTask.timer.getTimeLeft());
      currentTask.timer.onUpdate = setTimeLeft;
    }
  }, [currentTask]);

  const handleNextTask = () => {
    if (currentTaskIndex < tasks.length - 1) {
      handleStopTimer();
      const newIndex=currentTaskIndex + 1
      setCurrentTaskIndex(currentTaskIndex + 1);
      setCurrentTask(tasks[newIndex]);
    } 
    else {
      navigate('/user-home');
    }
  };
  const handlePreviousTask = () => {
    if (currentTaskIndex > 0) {
      handleStopTimer();
      const newIndex=currentTaskIndex - 1
      setCurrentTaskIndex(currentTaskIndex - 1);
      setCurrentTask(tasks[newIndex]);
    }
    else {
      navigate('/user-home');
    }
  };

  const handleStopTimer = () => {
    currentTask.timer.stop();
    currentTask.start=null;
    handleUpdateTask(currentTask);
  };
  const handleStarTimer = () => {
    if (currentTask.start === null || currentTask.start === undefined){
      currentTask.timer.start(new Date());
      currentTask.start=currentTask.timer.startTime;
      handleUpdateTask(currentTask);
    }
    else{
    currentTask.timer.start(currentTask.start);
    }
  }

  const handleCompleteTask = async () => {
      currentTask.complete=new Date();
      handleStopTimer();

    handleNextTask(); // Move to the next task upon completion
  };
  const handleUpdateTask = async (task) => {
    try {
      const response = await fetch(`/api/assigned_tasks/${task.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(task.toJSON()),
        credentials: 'include',
      });

      const data = await response.json();
      if (response.ok) {
        console.log("Task updated successfully", data);
      } else {
        console.error("Failed to update task", data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }
  const handleBackToHome = () => {
    handleStopTimer();
    navigate('/user-home');
  };

  return (
    <div>
      {tasks.length >0 ? (
        <div>
          <h2>Task Details</h2>
          <p>{timeLeft}</p>
          <p>{currentTask.title}</p>
          <button
            onClick={handleNextTask}
            disabled={currentTaskIndex >= tasks.length - 1}
          >
            Next Task
          </button>
          <button onClick={handlePreviousTask}>Previous Task</button>
          <button onClick={handleCompleteTask}>Completed</button>
          <button onClick={handleBackToHome}>Back to Home</button>
          <button onClick={handleStarTimer}>Start</button>
          <button onClick={handleStopTimer}>Stop</button>
        </div>
      ) : (
        <p>Loading task data...</p>
      )}
    </div>
  );
};

export default TaskView;



