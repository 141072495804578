import React from 'react';
import TaskForm from './TaskForm';

const TaskPage = () => {
  const handleTaskSubmit = async (task) => {
    console.log('Task Data:', task);
    try {

    // Assuming task is an instance of TaskMaster with a toJSON method
    const taskData = task.toJSON();
    console.log('Task Data:', taskData);

    // Send the task data to the backend using fetch
    const response = await fetch('/api/tasks', {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(taskData),
    })
    const data = await response.json();
    if (response.ok) {
      console.log("Task updated successfully", data);
    } else {
      console.error("Failed to update task", data);
    }
  } catch (error) {
    console.error("Error:", error);
  }
  };

  return (
    <div>
      <h2>Create New Task</h2>
      <TaskForm onSubmit={handleTaskSubmit} />
    </div>
  );
};

export default TaskPage;
