import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Task from '../utils/TaskMaster';
import { useNavigate } from 'react-router-dom';

const TaskForm = ({ onSubmit, initialTask = null }) => {
    const [task, setTask] = useState(initialTask || new Task({
        id: uuidv4(),
        user_id: 1,
        creation_date: new Date(),
        title: '',
        parent_id: null,
        category: '',
        complete: null,
        frequency: 0,
        working_time: 0,
        importance: 1,
        due_date: null,
        subtasks: []
    }));
    
    const navigate = useNavigate();

    const handleTaskChange = (e, taskId) => {
        const { name, value, type } = e.target;
        const updatedTask = task.getTask(taskId);

        if (updatedTask) {
            updatedTask[name] = (type === 'date') ? new Date(value) : value;
            setTask(new Task(task.toJSON()));
        }
    };

    const addSubtask = (parentId) => {
        const parentTask = task.getTask(parentId);

        if (parentTask) {
            const newSubtask = {
                id: uuidv4(),
                user_id: parentTask.user_id,
                creation_date: new Date(),
                title: '',
                parent_id: parentId,
                category: parentTask.category,
                complete: null,
                frequency: parentTask.frequency,
                working_time: 0,
                importance: parentTask.importance,
                due_date: parentTask.due_date,
                duration: null,
                subtasks: []
            };

            parentTask.addSubtask(newSubtask);
            setTask(new Task(task.toJSON()));
        }
    };

    const deleteSubtask = (taskId, subtaskId) => {
        const parentTask = task.getTask(taskId);

        if (parentTask) {
            parentTask.subtasks = parentTask.subtasks.filter((subtask) => subtask.id !== subtaskId);
            setTask(new Task(task.toJSON()));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await onSubmit(task);
        navigate('/user-home');
    };

    const renderTaskForm = (taskId) => {
        const currentTask = task.getTask(taskId);
        if (!currentTask) return null;
    
        const isLeaf = !currentTask.subtasks || currentTask.subtasks.length === 0;
    
        const handleDurationChange = (e, taskId, type) => {
            const { value } = e.target;
            const updatedTask = task.getTask(taskId);
            console.log(value);
    
            if (updatedTask) {
                const duration = updatedTask.duration ?? 0; // Default to 0 if no duration
                let hours = Math.floor(duration / 3600); // Extract hours from seconds
                let minutes = Math.floor((duration % 3600) / 60); // Extract minutes from seconds
    
                if (type === 'hours') {
                    hours = parseInt(value, 10);
                } else if (type === 'minutes') {
                    minutes = parseInt(value, 10);
                }
    
                // Convert the updated hours and minutes to seconds
                updatedTask.duration = hours * 3600 + minutes * 60;
                console.log(updatedTask.duration);
                console.log((new Task(task.toJSON())));
    
                setTask(new Task(task.toJSON()));
            }
        };
    
        return (
            <div style={{ marginLeft: currentTask.parent_id ? '20px' : '0px' }}>
                <h3>{currentTask.parent_id ? 'Subtask' : 'Parent Task'}</h3>
                <div>
                    <label>Title:</label>
                    <input
                        type="text"
                        name="title"
                        value={currentTask.title}
                        onChange={(e) => handleTaskChange(e, currentTask.id)}
                        required
                    />
                </div>
                <div>
                    <label>Category:</label>
                    <input
                        type="text"
                        name="category"
                        value={currentTask.category}
                        onChange={(e) => handleTaskChange(e, currentTask.id)}
                    />
                </div>
                <div>
                    <label>Frequency (every how many days):</label>
                    <input
                        type="number"
                        name="frequency"
                        value={currentTask.frequency ?? 0}
                        onChange={(e) => handleTaskChange(e, currentTask.id)}
                        min="0"
                    />
                </div>
                <div>
                    <label>Importance (1-10):</label>
                    <input
                        type="number"
                        name="importance"
                        value={currentTask.importance ?? 1}
                        onChange={(e) => handleTaskChange(e, currentTask.id)}
                        min="1"
                        max="10"
                    />
                </div>
                <div>
                    <label>Due Date:</label>
                    <input
                        type="date"
                        name="due_date"
                        value={currentTask.due_date ? currentTask.due_date.toISOString().substr(0, 10) : ''}
                        onChange={(e) => handleTaskChange(e, currentTask.id)}
                    />
                </div>
    
                {/* Display duration field only if the current task is a leaf */}
                {isLeaf && (
                    <div>
                        <label>Duration:</label>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <p>{currentTask.duration}</p>
                            <select
                                value={Math.floor((currentTask.duration ?? 0) / 3600)} // Extract hours from seconds
                                onChange={(e) => handleDurationChange(e, currentTask.id, 'hours')}
                            >
                                {Array.from({ length: 24 }, (_, i) => (
                                    <option key={i} value={i}>
                                        {i} hrs
                                    </option>
                                ))}
                            </select>
                            <select
                                value={Math.floor(((currentTask.duration ?? 0) % 3600) / 60)} // Extract minutes from seconds
                                onChange={(e) => handleDurationChange(e, currentTask.id, 'minutes')}
                            >
                                {Array.from({ length: 12 }, (_, i) => (
                                    <option key={i * 5} value={i * 5}>
                                        {i * 5} min
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                )}
    
                {/* Render subtasks recursively */}
                {currentTask.subtasks?.map((subtask) => (
                    <div key={subtask.id}>
                        {renderTaskForm(subtask.id)}
                        <button
                            type="button"
                            onClick={() => deleteSubtask(currentTask.id, subtask.id)}
                            style={{ marginTop: '5px' }}
                        >
                            Delete Subtask
                        </button>
                    </div>
                ))}
    
                <button type="button" onClick={() => addSubtask(currentTask.id)} style={{ marginTop: '10px' }}>
                    Add Subtask
                </button>
            </div>
        );
    };

    return (
        <form onSubmit={handleSubmit}>
            {renderTaskForm(task.id)}
            <button type="submit">{initialTask ? 'Update Task' : 'Create Task'}</button>
        </form>
    );
};

export default TaskForm;
